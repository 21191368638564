<template>
  <v-main>
    <div
      v-if="$store.state.valorLogo === true"
      class="d-flex flex-column justify-space-between align-center"
    >
      <v-img
        src="assets/banner.png"
        max-width="250"
        @click="$router.push(
          { path: '/data' },
          () => {
            $emit('reload')
          },
        )"
      />
    </div>
    <router-view />
    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      // DashboardCoreFooter: () => import('./Footer'),
    },
    logo: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.commit('SET_LOGO', val)
      },
    },
    data: () => ({
      valorImagen: false,
      url: '',
    }),
    mounted () {
      // this.url = localStorage.getItem('urlSitio') + '/assets/logo.jpeg'
      this.valorImagen = this.$store.state.valorEnviado
    },
    methods: {
      pruebaT () {
        location.reload()
      },
    },
  }
</script>
<style lang="sass">
  .top
    margin-top: 15px
</style>
